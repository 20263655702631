import { useContext, useState, useEffect } from 'react';
import { sidebarSvg, COLLAPSED_LOGO, EXPANDED_LOGO } from './sidebar-svg';
import './sidebar.style.css';
import { RoleContext } from '../../../context/role.context';
import { useNavigate, useParams } from 'react-router-dom';
import { CompanySelector } from './components/manager-company-selector/manager-company-selector.components';

interface SidebarControlProps {
  name: string;
  action: any;
  isActive: boolean;
  icon: any;
}

const renderButtonsByType = (
  userType: string,
  availableTypes: string[],
  controlData: SidebarControlProps,
  index: number
) => {
  if (!availableTypes.includes(userType)) return null;

  return (
    <SidebarControl
      name={controlData.name}
      action={controlData.action}
      isActive={controlData.isActive}
      icon={controlData.icon}
      key={index}
    />
  );
};

const SidebarControl = (props: SidebarControlProps) => {
  const { name, action, isActive, icon } = props;
  return (
    <button
      className={`sidebar-control ${isActive ? 'active' : ''}`}
      onClick={action}
    >
      {icon}
      <span className={'control-name'}>{name}</span>
    </button>
  );
};

export const SidebarHoc = () => {
  const navigate = useNavigate();
  const { role, userType, accessibleResources } = useContext(RoleContext);
  const { companyId } = useParams();
  if (!role || !userType) return null;

  if (!['MANAGER', 'HUBSPOT'].includes(userType)) return null;

  const isDashboardAccessible = () => {
    if (role === 'MANAGER') {
      return ['MANAGER'];
    }

    if (
      userType === 'HUBSPOT' &&
      accessibleResources &&
      accessibleResources.includes('DASHBOARD')
    ) {
      return ['HUBSPOT'];
    }

    return [];
  };

  const options = [
    {
      name: 'Dashboard',
      action: () => {
        navigate('/dashboard');
        if (role === 'MANAGER') {
          navigate(`/manager/companies/${companyId}/dashboard`);
        }
      },
      isActive: true,
      icon: sidebarSvg.DASHBOARD,
      availableUserTypes: isDashboardAccessible(),
    },
    {
      name: 'Fin reports',
      action: () => {
        navigate('/financial-reports');

        if (role === 'MANAGER') {
          navigate(`/manager/companies/${companyId}/financial-reports`);
        }
      },
      isActive: false,
      icon: sidebarSvg.FIN_REPORTS,
      availableUserTypes: ['MANAGER', 'HUBSPOT'],
    },
    {
      name: 'Tutorials',
      action: () => {
        navigate('/tutorials');
      },
      isActive: false,
      icon: sidebarSvg.TUTORIALS,
      availableUserTypes: [],
    },
    {
      name: 'Data sources',
      action: () => {
        navigate('/data-sources');
        if (role === 'MANAGER') {
          navigate(`/manager/companies/${companyId}/data-sources`);
        }
      },
      isActive: false,
      icon: sidebarSvg.DATA_SOURCES,
      availableUserTypes: ['MANAGER', 'HUBSPOT'],
    },
    {
      name: 'Mapping',
      action: () => {
        if (role === 'MANAGER') {
          navigate(`/manager/companies/${companyId}/mapping`);
        } else {
          navigate('/');
        }
      },
      isActive: false,
      icon: sidebarSvg.MAPPING,
      availableUserTypes: ['MANAGER'],
    },
  ];

  return <SideBar userType={userType} controls={options} />;
};

export const SideBar = ({
  controls,
  userType,
}: {
  controls: any[];
  userType: string;
}) => {
  const [sidebarExpanded, setSidebarExpanded] = useState(true);

  return (
    <div className={`sidebar ${sidebarExpanded ? 'hidden' : ''}`}>
      <div className={'sidebar-container'}>
        <div className={'sidebar-container-header'}>
          {userType === 'HUBSPOT' && <>{COLLAPSED_LOGO}</>}
          {userType === 'MANAGER' && <CompanySelector />}
        </div>
        <div className={'sidebar-container-body'}>
          {controls.map((option, index) => {
            return renderButtonsByType(
              userType,
              option.availableUserTypes,
              option,
              index
            );
          })}
        </div>
      </div>
      {/*Temporary disabled*/}
      {/*<button*/}
      {/*  onClick={() => {*/}
      {/*    setSidebarExpanded(!sidebarExpanded);*/}
      {/*  }}*/}
      {/*  className={'sidebar-hide'}*/}
      {/*>*/}
      {/*  {SIDEBAR_HIDE}*/}
      {/*  Hide panel*/}
      {/*</button>*/}
      {/*<button*/}
      {/*  onClick={() => {*/}
      {/*    setSidebarExpanded(false);*/}
      {/*  }}*/}
      {/*  className={'sidebar-open'}*/}
      {/*>*/}
      {/*  {SIDEBAR_EXPAND}*/}
      {/*  Expand panel*/}
      {/*</button>*/}
    </div>
  );
};
