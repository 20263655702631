import React, { useState } from 'react';
import './choose-base.style.css';
import { SquareX } from 'lucide-react';
import { UpdatedButtonComponent } from '../../../../shared-components/button/updated.button.component';
import {
  MappingSource,
  SourceType,
} from '../../../../tools/manager-api/types/mapping';

interface ChooseBaseModalProps {
  onClose: () => void;
  onConfirm: (integrationId: string, sourceType: SourceType) => void;
  categories: MappingSource[];
  sourceType: SourceType;
}

const sourceTypes = {
  ACCOUNT: 'financial accounts',
  EMPLOYEE: 'employees',
  CLIENT: 'clients',
  PRODUCT: 'products',
};

const ChooseBaseModal: React.FC<ChooseBaseModalProps> = ({
  onClose,
  onConfirm,
  categories,
  sourceType,
}) => {
  const [integrationId, setIntegrationId] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleChangeCategory = (integrationId: string) => {
    setIntegrationId(integrationId);
    setError(null);
  };

  const handleConfirmClick = () => {
    if (integrationId.trim() === '') {
      setError('Base integration is required');
      return;
    }

    onConfirm(integrationId, sourceType);
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="choose-base-modal">
        <div className="choose-base-modal__header">
          <h2>Choose the base</h2>
          <SquareX
            className="close-cross-button"
            onClick={onClose}
            style={{ width: '40px', height: '40px' }}
            strokeWidth={'0.7'}
          />
        </div>
        <div className="choose-base-modal__body">
          <p>For the {sourceTypes[sourceType]} list in the system</p>
          <div className="input-group">
            <select
              value={integrationId || ''}
              onChange={(e) => handleChangeCategory(e.target.value)}
              className="base-category-selector"
            >
              <option value="">Choose the basic data source</option>
              {categories.map((option, idx) => (
                <option key={idx} value={option.integrationId}>
                  {`${option.integrationName} ${option.integrationId.slice(
                    -4
                  )}`}
                </option>
              ))}
            </select>
            {error && <span className="error-message">{error}</span>}
          </div>
        </div>

        <UpdatedButtonComponent
          mini={true}
          type={'default'}
          onClick={handleConfirmClick}
        >
          Confirm
        </UpdatedButtonComponent>
      </div>
    </div>
  );
};

export default ChooseBaseModal;
