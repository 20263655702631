import React, { useState } from 'react';
import './add-account.style.css';
import { SquareX } from 'lucide-react';
import { UpdatedButtonComponent } from '../../../../shared-components/button/updated.button.component';

interface AddAccountModalProps {
  onClose: () => void;
  onAdd: (
    accountName: string,
    accountType: string,
    cacCalculation: boolean
  ) => void;
}

const AddAccountModal: React.FC<AddAccountModalProps> = ({
  onClose,
  onAdd,
}) => {
  const [accountName, setAccountName] = useState('');
  const [accountType, setAccountType] = useState('');
  const [cacCalculation, setCacCalculation] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleAddClick = () => {
    if (accountName.trim() === '') {
      setError('Account name is required');
      return;
    }

    onAdd(accountName, accountType, cacCalculation);
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="add-account-modal">
        <div className="add-account-modal__header">
          <h2>Add Account</h2>
          <SquareX
            className="close-cross-button"
            onClick={onClose}
            style={{ width: '45px', height: '45px' }}
            strokeWidth={'0.7'}
          />
        </div>
        <div className="add-account-modal__body">
          <div className="input-group">
            <input
              id="account-name"
              type="text"
              placeholder="Account name"
              value={accountName}
              onChange={(e) => {
                setAccountName(e.target.value);
                setError(null);
              }}
              name="accountName"
            />
            {error && <span className="error-message">{error}</span>}
          </div>
          <div className="input-group">
            <input
              id="account-type"
              type="text"
              placeholder="Account type"
              value={accountType}
              onChange={(e) => setAccountType(e.target.value)}
              name="accountType"
            />
          </div>
          <div className="input-group checkbox-group">
            <input
              id="cac-calculation"
              type="checkbox"
              checked={cacCalculation}
              onChange={(e) => setCacCalculation(e.target.checked)}
              name="cacCalculation"
            />
            <label htmlFor="cac-calculation">CAC Calculation</label>
          </div>
        </div>

        <UpdatedButtonComponent
          mini={true}
          type={'default'}
          onClick={handleAddClick}
        >
          Add
        </UpdatedButtonComponent>
      </div>
    </div>
  );
};

export default AddAccountModal;
