import { DashboardHttpService } from '../../tools/api-services/dashboardHttpService';
import { useEffect, useState } from 'react';
import { ChartResponse } from '../../tools/api-services/types/chart.response';

export const useClientCharts = () => {
  const [charts, setCharts] = useState<ChartResponse[]>([]);

  useEffect(() => {
    const getCharts = async () => {
      try {
        const response = await DashboardHttpService.getCharts();
        setCharts(response.data.result);
      } catch (e) {
        setCharts([]);
      }
    };

    getCharts();
  }, []);

  return {
    charts,
  };
};
