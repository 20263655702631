import { BrowserRouter, Routes } from 'react-router-dom';
import { useState } from 'react';
import { SuccessToast } from './shared-components/success-toast/success-toast';
import { ErrorToast } from './shared-components/error-toast/error-toast';
import {
  OnboardingContext,
  OnboardingContextType,
} from './context/onboarding.context';
import { HomeRedirectContext } from './context/home-redirect.context';
import { ToasterContext } from './context/toaster.context';
import { RoleContext } from './context/role.context';
import { AppRouter } from './router';
import { UserRole, UserType } from './tools/api-services/types/client';
import { UserContext, useUserDetails } from './context/user-details.context';
import { initializeApp } from 'firebase/app';

function App() {
  const appSettings = {
    apiKey: 'AIzaSyAIunUSKqKIrWhpcgiRhDj3M8HWxoW-9tE',
    authDomain: 'app.fuelfinance.me',
    projectId: 'fuel-965d2',
    storageBucket: 'fuel-965d2.appspot.com',
    messagingSenderId: '514563667324',
    appId: '1:514563667324:web:e5006ecf0872b17239a0f9',
    measurementId: 'G-VF56SPFKK3',
  };

  // todo? replace with incode

  initializeApp(appSettings);

  const [onboardingData, setOnboardingData] = useState<OnboardingContextType>({
    businessModel: '',
    experience: '',
    needs: [],
    package: '',
  });

  const [homeRedirectUrl, setHomeRedirectUrl] = useState(
    'https://fuelfinance.me/bootstrap/'
  );

  const [toasterData, setToasterData] = useState<{
    isVisible: boolean;
    content: string;
    isError: boolean;
  }>({
    isVisible: false,
    content: '',
    isError: false,
  });

  const updateToaster = ({
    content,
    isError = false,
  }: {
    content: string;
    isError?: boolean;
  }) => {
    setToasterData({ isVisible: true, content, isError });
    setTimeout(() => {
      setToasterData({ isVisible: false, content: '', isError: false });
    }, 5000);
  };

  const [role, setRole] = useState<UserRole | null>(null);
  const [userType, setUserType] = useState<UserType>('UNKNOWN');
  const [accessibleResources, setAccessibleResources] = useState<string[]>([]);

  const { userDetails, setUserDetails } = useUserDetails();
  return (
    <div className="App">
      <RoleContext.Provider
        value={{
          role,
          userType,
          accessibleResources,
          setRoleType: (
            userType:
              | 'BOOTSTRAP'
              | 'HUBSPOT'
              | 'MANUAL'
              | 'MANAGER'
              | 'UNKNOWN'
              | 'BOOTSTRAP_NEXT'
          ) => {
            setUserType(userType);
          },
          setRole: (role: UserRole | null) => {
            if (!role) {
              return;
            }
            setRole(role);

            // todo remove this, as makes no sense
            localStorage.setItem('role', role || '');
          },
          setAccessibleResources: (resources: string[]) => {
            setAccessibleResources(resources);
          },
        }}
      >
        <UserContext.Provider value={{ userDetails, setUserDetails }}>
          <HomeRedirectContext.Provider
            value={{ homeRedirectUrl, setHomeRedirectUrl }}
          >
            <OnboardingContext.Provider
              value={{ ...onboardingData, setOnboardingData }}
            >
              <ToasterContext.Provider
                value={{ ...toasterData, updateToaster }}
              >
                {toasterData.isVisible &&
                  (toasterData.isError ? <ErrorToast /> : <SuccessToast />)}
                <BrowserRouter>
                  <Routes>{AppRouter()}</Routes>
                </BrowserRouter>
              </ToasterContext.Provider>
            </OnboardingContext.Provider>
          </HomeRedirectContext.Provider>
        </UserContext.Provider>
      </RoleContext.Provider>
    </div>
  );
}

export default App;
