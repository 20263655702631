import { DateTime } from 'luxon';
import { DiagramStringValueFormatBuilder } from '../../../../utils/dashboard/formatters';

export interface HeaderItemProps {
  name: string;
  value: {
    year: number;
    month: number;
    monthData: {
      value: number;
    };
    prevMonthData: {
      value: number;
    };
  };
  valueType: 'percent' | 'sum' | 'number';
}

export const HeaderItem = ({
  name,
  value,
  valueType = 'sum',
}: HeaderItemProps) => {
  if (!value.monthData || !value.prevMonthData)
    return <div className={'actual-state-card'}>No data available</div>;

  const {
    monthData: { value: monthDataValue },
    prevMonthData: { value: prevMonthDataValue },
  } = value;

  const date = (() => {
    if (value.year && value.month) {
      return DateTime.fromObject({
        year: value.year,
        month: value.month,
      }).toFormat('MMM-yy');
    }

    const now = DateTime.now();
    const lastClosedMonth = now.minus({ months: 1 });

    return lastClosedMonth.toFormat('MMM-yy');
  })();

  const calculateMomDiff = (
    monthDataValue: number,
    prevMonthDataValue?: number
  ) => {
    if (!prevMonthDataValue) return 0;
    return ((monthDataValue - prevMonthDataValue) / prevMonthDataValue) * 100;
  };

  const momValue = calculateMomDiff(monthDataValue, prevMonthDataValue);

  const sign = momValue > 0 ? '↑' : '↓';
  const formattedValue =
    valueType === 'sum'
      ? new DiagramStringValueFormatBuilder(monthDataValue)
          .toThousands(1)
          .includeK()
          .includeDollarSign()
          .getValue()
      : monthDataValue;

  return (
    <div className="actual-state-card">
      <div className="actual-state-card-header">
        <span className="actual-state-card-title">{name}</span>
      </div>
      <div className="actual-state-card-body">
        <span className="actual-state-card-amount">{formattedValue}</span>
      </div>
      <div className="actual-state-card-footer">
        <span
          style={{ color: momValue < 0 ? 'red' : '#4caf50' }}
          className="actual-state-card-change positive"
        >
          {sign} MOM {momValue.toFixed(0)}%
        </span>
        <span
          style={{
            color: '#1D1B2080',
            fontSize: '10px',
            marginLeft: '14px',
          }}
        >
          for {date}
        </span>
      </div>
    </div>
  );
};
