import { useEffect, useState, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  Mapping,
  MappingMasterList,
  MappingSource,
  SourceType,
} from '../../../tools/manager-api/types/mapping';
import { ManagerHttpMappingService } from '../../../tools/manager-api/manager-http-mapping';
import { Edit3, Trash2, ArrowUp, ArrowDown } from 'lucide-react';
import AddAccountModal from '../modals/add-account/add-account.modal';
import UpdateAccountModal from '../modals/update-account/update-account.modal';
import './financial-account.style.css';
import arrowImg from '../../../../../public/images/arrow.png';
import { ToasterContext } from '../../../context/toaster.context';
import ChooseBaseModal from '../modals/choose-base/choose-base.modal';
import { SearchBar } from '../components/search-bar.component';
import { Pagination } from '../components/pagination.component';
import { Loader } from '../components/loader.component';
import { CustomSelectWithSearch } from '../components/custom-select-search.component';
import { UpdatedButtonComponent } from '../../../shared-components/button/updated.button.component';
const PAGE_LIMIT = 50;

export const FinancialAccounts = () => {
  const { companyId } = useParams();
  const [activeIntegration, setActiveIntegration] = useState<string | null>(
    null
  );
  const [addAccountModalActive, setAddAccountModalActive] =
    useState<boolean>(false);
  const [updateAccountModalActive, setUpdateAccountModalActive] =
    useState<boolean>(false);
  const [accountToUpdate, setAccountToUpdate] = useState<{
    id: number;
    accountName: string;
    accountType: string;
    cacCalculation: boolean;
  } | null>(null);

  const [fuelRecommendedAccounts, setFuelRecommendedAccounts] = useState<
    MappingMasterList[]
  >([]);
  const [integrations, setIntegrations] = useState<MappingSource[] | null>(
    null
  );
  const [integrationMappings, setIntegrationMappings] = useState<
    Mapping[] | null
  >(null);
  const [updatedMappings, setUpdatedMappings] = useState<
    { id: number; from: string; to: string }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [chooseBaseModalActive, setChooseBaseModalActive] =
    useState<boolean>(false);
  const [masterSearch, setMasterSearch] = useState<string>('');
  const [mappingSearch, setMappingSearch] = useState<string>('');
  const [masterPage, setMasterPage] = useState(1);
  const [mappingPage, setMappingPage] = useState(1);
  const [totalMasterCount, setTotalMasterCount] = useState(0);
  const [totalMappingCount, setTotalMappingCount] = useState(0);
  const [allMasterListData, setAllMasterListData] = useState<
    MappingMasterList[]
  >([]);
  const [masterSorting, setMasterSorting] = useState<{
    field: string;
    order: 'asc' | 'desc';
  }>({
    field: 'name',
    order: 'asc',
  });
  const [mappingSorting, setMappingSorting] = useState<{
    field: string;
    order: 'asc' | 'desc';
  }>({
    field: 'name',
    order: 'asc',
  });

  const ctx = useContext(ToasterContext);

  const fetchIntegrationsAndMasterLists = useCallback(
    async (searchTerm: string) => {
      setLoading(true);
      try {
        const { data: integrations } =
          await ManagerHttpMappingService.getAvailableIntegrations(
            Number(companyId),
            'ACCOUNT'
          );

        const {
          data: { data: masterLists, totalCount },
        } = await ManagerHttpMappingService.filterMasterList(
          Number(companyId),
          'ACCOUNT',
          {
            page: masterPage,
            limit: PAGE_LIMIT,
            search: searchTerm,
            sorting: [{ key: masterSorting.field, order: masterSorting.order }],
          }
        );

        setIntegrations(integrations);
        setFuelRecommendedAccounts(masterLists);
        setTotalMasterCount(totalCount);

        if (!allMasterListData?.length && masterLists.length === 0) {
          setChooseBaseModalActive(true);
        }
      } catch {
        ctx.updateToaster({
          content: 'Failed to fetch master list and integrations',
          isError: true,
        });
      } finally {
        setLoading(false);
      }
    },
    [companyId, masterPage, masterSorting]
  );

  const fetchMappings = useCallback(
    async (searchTerm: string) => {
      if (activeIntegration === null) return;

      setLoading(true);
      try {
        const {
          data: { data: mappings, totalCount },
        } = await ManagerHttpMappingService.getMappingList(
          Number(companyId),
          'ACCOUNT',
          Number(activeIntegration),
          {
            page: mappingPage,
            limit: PAGE_LIMIT,
            search: searchTerm,
            sorting: [
              { key: mappingSorting.field, order: mappingSorting.order },
            ],
          }
        );
        setIntegrationMappings(mappings);
        setTotalMappingCount(totalCount);
      } catch {
        ctx.updateToaster({
          content: 'Failed to fetch mappings',
          isError: true,
        });
      } finally {
        setLoading(false);
      }
    },
    [activeIntegration, companyId, mappingPage, mappingSorting]
  );

  useEffect(() => {
    fetchIntegrationsAndMasterLists(masterSearch);
  }, [fetchIntegrationsAndMasterLists, mappingSorting]);

  useEffect(() => {
    fetchMappings(mappingSearch);
  }, [fetchMappings, mappingSorting]);

  useEffect(() => {
    const fetchAllMasterListData = async () => {
      try {
        setLoading(true);
        const { data } = await ManagerHttpMappingService.getMasterList(
          Number(companyId),
          'ACCOUNT'
        );
        setAllMasterListData(data);
      } catch {
        ctx.updateToaster({
          content: 'Failed to fetch master list',
          isError: true,
        });
      } finally {
        setLoading(false);
      }
    };
    fetchAllMasterListData();
  }, [companyId, fuelRecommendedAccounts]);

  const handleAddAccount = async (
    accountName: string,
    accountType: string,
    cacCalculation: boolean
  ) => {
    setLoading(true);
    try {
      await ManagerHttpMappingService.addMasterList(Number(companyId), {
        name: accountName,
        sourceType: 'ACCOUNT',
        settings: { accountType, cacCalculation },
      });
      setAddAccountModalActive(false);
      await fetchIntegrationsAndMasterLists(masterSearch);
    } catch {
      ctx.updateToaster({
        content: 'Failed to add master account',
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteMasterAccount = async (accountId: number) => {
    setLoading(true);
    try {
      await ManagerHttpMappingService.deleteMasterList(
        Number(companyId),
        accountId,
        'ACCOUNT'
      );
      await fetchIntegrationsAndMasterLists(masterSearch);
    } catch {
      ctx.updateToaster({
        content: 'Failed to delete master account',
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenUpdateModal = (account: {
    id: number;
    accountName: string;
    accountType: string;
    cacCalculation: boolean;
  }) => {
    setAccountToUpdate(account);
    setUpdateAccountModalActive(true);
  };

  const handleUpdateMasterAccount = async (
    accountId: number,
    accountName: string,
    accountType?: string,
    cacCalculation?: boolean
  ) => {
    setLoading(true);
    try {
      await ManagerHttpMappingService.updateMasterList(
        Number(companyId),
        accountId,
        {
          name: accountName,
          sourceType: 'ACCOUNT',
          settings: { accountType, cacCalculation },
        }
      );
      setUpdateAccountModalActive(false);
      await fetchIntegrationsAndMasterLists(masterSearch);
    } catch {
      ctx.updateToaster({
        content: 'Failed to update master account',
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleMappingChange = (mappingId: number, value: string) => {
    setIntegrationMappings((prev) => {
      if (!prev) {
        return null;
      }

      const newMappings = prev.map((mapping) =>
        mapping.id === mappingId ? { ...mapping, mappedName: value } : mapping
      );
      return newMappings;
    });

    setUpdatedMappings((prev) => {
      const existing = prev.find((mapping) => mapping.id === mappingId);

      if (existing) {
        const updated = prev.map((mapping) =>
          mapping.id === mappingId ? { ...mapping, to: value } : mapping
        );
        return updated;
      }

      const originalMapping = integrationMappings?.find(
        (mapping) => mapping.id === mappingId
      );

      if (!originalMapping) {
        return prev;
      }

      const newMapping = {
        id: mappingId,
        from: originalMapping.name || '',
        to: value,
      };

      return [...prev, newMapping];
    });
  };

  const handleRemap = async (
    updatedMappings: { id: number; from: string; to: string }[]
  ) => {
    if (!updatedMappings?.length) {
      ctx.updateToaster({
        content: 'No changes to update',
        isError: true,
      });
      return;
    }
    setLoading(true);
    try {
      await ManagerHttpMappingService.remap(Number(companyId), {
        integrationId: Number(activeIntegration),
        sourceType: 'ACCOUNT',
        updatedMappings,
      });
      setUpdatedMappings([]);
      await fetchMappings(mappingSearch);
      ctx.updateToaster({
        content:
          'The remapping process has begun and may take a few minutes to complete.',
        isError: false,
      });
    } catch {
      ctx.updateToaster({ content: 'Failed to remap', isError: true });
    } finally {
      setLoading(false);
    }
  };

  const handleChooseBase = async (
    integrationId: string,
    sourceType: SourceType
  ) => {
    setLoading(true);
    try {
      await ManagerHttpMappingService.addMasterLists(Number(companyId), {
        integrationId,
        sourceType,
      });
      await fetchIntegrationsAndMasterLists(masterSearch);
    } catch {
      ctx.updateToaster({
        content: 'Failed to add master account',
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleMasterSearch = () => {
    setMasterPage(1);
    fetchIntegrationsAndMasterLists(masterSearch);
  };

  const handleMappingSearch = () => {
    setMappingPage(1);
    fetchMappings(mappingSearch);
  };

  const toggleMasterSorting = () => {
    setMasterSorting((prev) => ({
      field: 'name',
      order: prev.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  const toggleMappingSorting = () => {
    setMappingSorting((prev) => ({
      field: 'name',
      order: prev.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  const renderTableHeaders = () => {
    if (activeIntegration === null) {
      return (
        <tr>
          <th
            className="master-table-header"
            onClick={toggleMasterSorting}
            style={{ cursor: 'pointer' }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>ACCOUNT NAME</span>
              {masterSorting.order === 'asc' ? (
                <ArrowUp size={20} style={{ marginLeft: '8px' }} />
              ) : (
                <ArrowDown size={20} style={{ marginLeft: '8px' }} />
              )}
            </div>
          </th>
          <th className="master-table-header">TYPE OF THE ACCOUNT</th>
          <th className="master-table-header">CAC CALCULATION</th>
          <th
            onClick={() => setAddAccountModalActive(true)}
            style={{ cursor: 'pointer', textAlign: 'end' }}
            className="master-table-header"
          >
            <span
              style={{
                textDecoration: 'underline',
                color: 'black',
                fontWeight: 'normal',
                fontSize: '1rem',
                margin: '0 0.5rem',
              }}
            >
              + ACCOUNT
            </span>
          </th>
        </tr>
      );
    }

    return (
      <tr>
        <th
          className="mapping-table-header"
          onClick={toggleMappingSorting}
          style={{ cursor: 'pointer' }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>ACCOUNT NAME</span>
            {mappingSorting.order === 'asc' ? (
              <ArrowUp size={20} style={{ marginLeft: '8px' }} />
            ) : (
              <ArrowDown size={20} style={{ marginLeft: '8px' }} />
            )}
          </div>
        </th>
        <th></th>
        <th>ACCOUNT IN FUEL SYSTEM</th>
        <th style={{ textAlign: 'end' }}>
          {updatedMappings?.length > 0 && (
            <UpdatedButtonComponent
              onClick={() => {
                handleRemap(updatedMappings);
              }}
              mini={false}
              type="default"
            >
              UPDATE MAPPING
            </UpdatedButtonComponent>
          )}
        </th>
      </tr>
    );
  };

  const renderTableRows = () => {
    if (activeIntegration === null) {
      return (
        <>
          {fuelRecommendedAccounts.map((account, index) => (
            <tr key={index}>
              <td className="master-table-row">{account.name}</td>
              <td className="master-table-row">
                {account?.settings?.accountType || ''}
              </td>
              <td className="master-table-row">
                {account?.settings?.cacCalculation ? 'YES' : 'NO'}
              </td>
              <td className="actions master-table-row">
                <Edit3
                  className="edit-icon"
                  onClick={() =>
                    handleOpenUpdateModal({
                      id: account.id,
                      accountName: account.name,
                      accountType: account?.settings?.accountType || '',
                      cacCalculation:
                        account?.settings?.cacCalculation || false,
                    })
                  }
                />
                <Trash2
                  className="delete-icon"
                  onClick={() => handleDeleteMasterAccount(account.id)}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ textAlign: 'end' }}>
              <Pagination
                page={masterPage}
                totalCount={totalMasterCount}
                onPageChange={setMasterPage}
              />
            </td>
          </tr>
        </>
      );
    }

    return (
      <>
        {integrationMappings?.map((mapping, index) => (
          <tr key={index}>
            <td className="mapping-account">{mapping.name}</td>
            <td className="mapping-arrow">
              <img
                src={arrowImg}
                alt="arrow"
                style={{ width: '24px', height: '20px' }}
              />
            </td>
            <td className="mapping-fuel-account">
              <CustomSelectWithSearch
                options={allMasterListData}
                value={mapping.mappedName}
                onChange={(selectedValue: string) =>
                  handleMappingChange(mapping.id, selectedValue)
                }
                placeholder="Choose account"
              />
            </td>
          </tr>
        ))}
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td style={{ textAlign: 'end' }}>
            <Pagination
              page={mappingPage}
              totalCount={totalMappingCount}
              onPageChange={setMappingPage}
            />
          </td>
        </tr>
      </>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="mapping-financial-accounts">
      <div className="mapping-sub-menu">
        <div
          className={`mapping-sub-menu-item ${
            activeIntegration === null ? 'active' : ''
          }`}
          onClick={() => {
            setActiveIntegration(null);
            if (mappingSearch) {
              setMappingSearch('');
              setMappingPage(1);
              fetchMappings('');
            }
          }}
        >
          master list
        </div>
        {integrations?.map((integration, index) => (
          <div
            key={index}
            className={`mapping-sub-menu-item ${
              activeIntegration === integration.integrationId ? 'active' : ''
            }`}
            onClick={() => {
              setActiveIntegration(integration.integrationId);
              if (masterSearch) {
                setMasterSearch('');
                setMasterPage(1);
                fetchIntegrationsAndMasterLists('');
              }
            }}
          >
            {`${integration.integrationName} ${integration.integrationId.slice(
              -4
            )}`}
          </div>
        ))}
      </div>
      <div className="mapping-search-action">
        {activeIntegration === null && (
          <SearchBar
            searchValue={masterSearch}
            setSearchValue={setMasterSearch}
            placeholder="Type account name"
            onSearch={handleMasterSearch}
          />
        )}
        {activeIntegration !== null && (
          <SearchBar
            searchValue={mappingSearch}
            setSearchValue={setMappingSearch}
            placeholder="Type account name"
            onSearch={handleMappingSearch}
          />
        )}
      </div>
      <table
        className={
          activeIntegration === null ? 'master-table' : 'mapping-table'
        }
      >
        <thead>{renderTableHeaders()}</thead>
        <tbody>{renderTableRows()}</tbody>
      </table>

      {addAccountModalActive && (
        <AddAccountModal
          onAdd={handleAddAccount}
          onClose={() => setAddAccountModalActive(false)}
        />
      )}
      {updateAccountModalActive && (
        <UpdateAccountModal
          account={accountToUpdate}
          onSave={handleUpdateMasterAccount}
          onClose={() => setUpdateAccountModalActive(false)}
        />
      )}
      {chooseBaseModalActive && (
        <ChooseBaseModal
          onClose={() => setChooseBaseModalActive(false)}
          onConfirm={handleChooseBase}
          categories={integrations || []}
          sourceType="ACCOUNT"
        />
      )}
    </div>
  );
};
