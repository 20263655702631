import React, { useState } from 'react';
import { Container } from '../../shared-components/container';
import Footer from '../../shared-components/footer/footer.component';
import { Preloader } from '../../shared-components/preloader';
import { BasePage } from '../../pages/base';
import { PaymentSystemsSource } from './components/payment-systems/payment-systems';
import { AccountingSystemsSource } from './components/accounting-systems/accounting-systems';
import { GSheetSource } from './components/gsheet/gsheet';
import stripeIcon from '../../../../public/images/stripe.png';
import quickbooksIcon from '../../../../public/images/qb.png';
import gsheetIcon from '../../../../public/images/gsheet.png';
import hubspotIcon from '../../../../public/images/hubspot-icon.svg';
import paylocityIcon from '../../../../public/images/paylocity.svg';
import paypalIcon from '../../../../public/images/paypal.png';
import salesforceIcon from '../../../../public/images/salesforce.png';
import gustoIcon from '../../../../public/images/gusto.svg';
import DataSourceItem from './data-source-item/data-source-item';
import { useDataSource } from './data-source.hook';
import './styles.css';
import { useUser } from '../../context/user-details.context';
import { trackOpenTemplate } from '../../tools/analytics-events';
import { PayrollSource } from './components/payroll/payroll';
import { CrmSystemsSource } from './components/crm-systems/crm-systems';
import { DeleteDataSourceModal } from './components/delete-data-source-modal/delete-data-source.modal';
import { SourceType } from '../../tools/api-services/types/connected-sources';

export const DataSourcePage = () => {
  const [sourceIdToDelete, setSourceIdToDelete] = useState<number | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteHandler, setDeleteHandler] = useState<
    ((sourceId: number) => void) | null
  >(null);
  const role = localStorage.getItem('role');
  const { userDetails } = useUser();
  const [isIntegrationLoading, setIsIntegrationLoading] = useState(false);
  const {
    connectedSources,
    handleDeleteSalesIntegration,
    handleImportSalesIntegration,
    handleDeleteQuickbooksIntegration,
    handleImportQuickbooksIntegration,
    handleDeleteGSheetConnection,
    handleImportPayrollIntegration,
    handleDeletePayrollIntegration,
    handleImportCrmIntegration,
    handleDeleteCrmIntegration,
    handleImportGSheetConnection,
  } = useDataSource();

  const handleLoadingState = (isLoading: boolean) => {
    setIsIntegrationLoading(isLoading);
  };

  const handleDeleteIconClick = (sourceId: number, sourceType: SourceType) => {
    setIsDeleteModalOpen(true);
    setSourceIdToDelete(sourceId);
    setDeleteHandler(() => handleDeleteMap[sourceType]);
  };

  const handleCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteMap = {
    sales: handleDeleteSalesIntegration,
    quickbooks: handleDeleteQuickbooksIntegration,
    gsheet: handleDeleteGSheetConnection,
    payroll: handleDeletePayrollIntegration,
    crm: handleDeleteCrmIntegration,
  };

  const providerIcons = {
    GUSTO: gustoIcon,
    PAYLOCITY: paylocityIcon,
    STRIPE: stripeIcon,
    PAYPAL: paypalIcon,
    HUBSPOT: hubspotIcon,
    SALES_FORCE: salesforceIcon,
  };

  return (
    <>
      {isIntegrationLoading && (
        <>
          <div className="loader-overlay"></div>
          <div className="loader"></div>
        </>
      )}
      <Preloader isLoading={false} />
      <BasePage>
        <Container extended={true}>
          <div className="add-data-source-header">
            <span style={{ fontSize: '24px', lineHeight: '28px' }}>
              Add data source
            </span>
            {role === 'MANAGER' && (
              <div className="template-csv-files">
                <a
                  href="https://docs.google.com/spreadsheets/d/1rTuTaTh7FQF5BEzysU1Xrm-WwB95Sosw2_hlGmyDRx8/edit?gid=594633118#gid=594633118"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    trackOpenTemplate({
                      companyName: userDetails.companyName,
                      userName: userDetails.userName,
                      userType: userDetails.userType,
                    })
                  }
                >
                  GS template with data structures
                </a>
              </div>
            )}
          </div>
          <div className="data-source-items">
            <PaymentSystemsSource setLoading={handleLoadingState} />
            <AccountingSystemsSource />
            <PayrollSource setLoading={handleLoadingState} />
            <CrmSystemsSource setLoading={handleLoadingState} />
            {role === 'MANAGER' && <GSheetSource />}
          </div>
          <div>
            <span style={{ fontSize: '24px', lineHeight: '28px' }}>
              Added sources
            </span>
            {connectedSources.paymentSystems.length === 0 &&
              connectedSources.accountingSystems.length === 0 &&
              connectedSources.crmSystems.length === 0 &&
              connectedSources.payrollSystems.length === 0 &&
              connectedSources.crmSystems.length === 0 &&
              connectedSources.payrollSystems.length === 0 &&
              connectedSources.gsheetFiles.length === 0 && (
                <div className="no-added-sources">
                  <img
                    src="/images/no-sources.png"
                    alt="No added sources"
                    style={{ width: '32px', height: '32px' }}
                  ></img>
                  <h3>No added sources</h3>
                </div>
              )}
            <div className="added-sources" style={{ marginTop: '10px' }}>
              {connectedSources.paymentSystems.length > 0 && (
                <>
                  <span style={{ fontSize: '15px', lineHeight: '20px' }}>
                    Payment systems
                  </span>
                  <div className="data-source-divider-horizontal"></div>
                  {connectedSources.paymentSystems.map((source, index) => (
                    <div key={index}>
                      <DataSourceItem
                        key={source.id}
                        source={source}
                        handleDelete={() =>
                          handleDeleteIconClick(source.id, 'sales')
                        }
                        handleImport={
                          role === 'MANAGER'
                            ? () =>
                                handleImportSalesIntegration(
                                  source.id,
                                  source.provider
                                )
                            : undefined
                        }
                        icon={providerIcons[source.provider]}
                        type="payment"
                      />
                      {index !== connectedSources.paymentSystems.length - 1 ? (
                        <div className="data-source-divider-horizontal"></div>
                      ) : (
                        <br />
                      )}
                    </div>
                  ))}
                </>
              )}
              {connectedSources.accountingSystems.length > 0 && (
                <>
                  <span style={{ fontSize: '15px', lineHeight: '20px' }}>
                    Accounting systems
                  </span>
                  <div className="data-source-divider-horizontal"></div>
                  {connectedSources.accountingSystems.map((source, index) => (
                    <div key={index}>
                      <DataSourceItem
                        key={source.id}
                        source={source}
                        handleDelete={() =>
                          handleDeleteIconClick(source.id, 'quickbooks')
                        }
                        handleImport={
                          role === 'MANAGER'
                            ? () => handleImportQuickbooksIntegration(source.id)
                            : undefined
                        }
                        icon={quickbooksIcon}
                        type="accounting"
                      />
                      {index !==
                      connectedSources.accountingSystems.length - 1 ? (
                        <div className="data-source-divider-horizontal"></div>
                      ) : (
                        <br />
                      )}
                    </div>
                  ))}
                </>
              )}
              {connectedSources.crmSystems.length > 0 && (
                <>
                  <span style={{ fontSize: '15px', lineHeight: '20px' }}>
                    CRM systems
                  </span>
                  <div className="data-source-divider-horizontal"></div>
                  {connectedSources.crmSystems.map((source, index) => (
                    <div key={index}>
                      <DataSourceItem
                        key={source.id}
                        source={source}
                        handleDelete={() =>
                          handleDeleteIconClick(source.id, 'crm')
                        }
                        handleImport={
                          role === 'MANAGER'
                            ? () =>
                                handleImportCrmIntegration(
                                  source.id,
                                  source.provider
                                )
                            : undefined
                        }
                        icon={providerIcons[source.provider]}
                        type="crm"
                      />
                      {index !== connectedSources.crmSystems.length - 1 ? (
                        <div className="data-source-divider-horizontal"></div>
                      ) : (
                        <br />
                      )}
                    </div>
                  ))}
                </>
              )}
              {connectedSources.payrollSystems.length > 0 && (
                <>
                  <span style={{ fontSize: '15px', lineHeight: '20px' }}>
                    HRIS systems
                  </span>
                  <div className="data-source-divider-horizontal"></div>
                  {connectedSources.payrollSystems.map((source, index) => (
                    <div key={index}>
                      <DataSourceItem
                        key={source.id}
                        source={source}
                        handleDelete={() =>
                          handleDeleteIconClick(source.id, 'payroll')
                        }
                        handleImport={
                          role === 'MANAGER'
                            ? () => handleImportPayrollIntegration(source.id)
                            : undefined
                        }
                        icon={providerIcons[source.provider]}
                        type="payroll"
                      />
                      {index !== connectedSources.payrollSystems.length - 1 ? (
                        <div className="data-source-divider-horizontal"></div>
                      ) : (
                        <br />
                      )}
                    </div>
                  ))}
                </>
              )}
              {connectedSources.gsheetFiles.length > 0 &&
                role === 'MANAGER' && (
                  <>
                    <span style={{ fontSize: '15px', lineHeight: '20px' }}>
                      Google Sheets
                    </span>
                    <div className="data-source-divider-horizontal"></div>
                    {connectedSources.gsheetFiles?.map((source, index) => (
                      <div key={index}>
                        <DataSourceItem
                          key={source.id}
                          source={source}
                          handleDelete={() =>
                            handleDeleteIconClick(source.id, 'gsheet')
                          }
                          handleImport={
                            role === 'MANAGER'
                              ? () => handleImportGSheetConnection(source.id)
                              : undefined
                          }
                          icon={gsheetIcon}
                          type="gsheet"
                        />
                        {index !== connectedSources.gsheetFiles.length - 1 ? (
                          <div className="data-source-divider-horizontal"></div>
                        ) : (
                          <br />
                        )}
                      </div>
                    ))}
                  </>
                )}
            </div>
          </div>
          {isDeleteModalOpen && (
            <>
              <div
                className="modal-overlay"
                onClick={() => setIsDeleteModalOpen(false)}
              />
              <DeleteDataSourceModal
                onClose={handleCloseModal}
                sourceId={sourceIdToDelete}
                handleDelete={deleteHandler}
              />
            </>
          )}
        </Container>
        <Footer />
      </BasePage>
    </>
  );
};
