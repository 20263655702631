import { ResponsiveLine } from '@nivo/line';
import { DefaultTooltipBody } from './components/default-tooltip';
import { formatDate } from '../../../../utils/dashboard/formatters';
interface LineProps {
  data: any;
  options?: any;
}

const CustomPointLabelLayer =
  (properties: any) =>
  ({ points }: any) =>
    (
      <>
        {points.map((point: any, index: number) => {
          const val = properties.options?.percents
            ? ((point.data.y as number) * 100).toFixed(1) + '%'
            : point.data.y;

          return (
            <g key={index} transform={`translate(${point.x}, ${point.y - 10})`}>
              <text
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                  fontSize: 10,
                  stroke: 'white',
                  strokeWidth: 3,
                  paintOrder: 'stroke',
                  pointerEvents: 'none',
                }}
              >
                {val}
              </text>
              <text
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                  fontSize: 10,
                  fill: 'black',
                  pointerEvents: 'none',
                }}
              >
                {val}
              </text>
            </g>
          );
        })}
      </>
    );
export const LineChart = (properties: LineProps) => {
  const { dataset } = properties.data;

  const lines = dataset.map((data: any) => {
    return {
      id: data.key,
      data: data.data,
    };
  });

  return (
    <ResponsiveLine
      data={lines}
      margin={{ top: 50, right: 20, bottom: 60, left: 30 }}
      enableGridY={false}
      enableGridX={false}
      curve={properties.data?.curve || 'linear'}
      enablePoints={
        properties.data?.enablePoints !== undefined
          ? properties.data?.enablePoints
          : true
      }
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false,
      }}
      axisLeft={null}
      axisBottom={{
        format: (value: string) => formatDate(value),
        tickValues: lines[0]?.data.map((d: { x: number }) => d.x),
        tickSize: 0,
        tickPadding: 30,
      }}
      colors={properties.options?.colors || { scheme: 'greens' }}
      useMesh={true}
      // pointLabel={(v) => {
      //   const val = properties.options?.percents
      //     ? ((v.data.y as number) * 100).toFixed(1) + '%'
      //     : v.data.y;
      //   return `${val}`;
      // }}
      tooltip={({ point }) => {
        const { id, data } = point;
        const value = properties.options?.percents
          ? `${((data.y as number) * 100).toFixed(1)}%`
          : data.y;
        const date = data.x;
        return (
          <DefaultTooltipBody color={point.color}>
            <p>{id}</p>
            <p>{formatDate(date as string)}</p>
            <p>{value as number}</p>
          </DefaultTooltipBody>
        );
      }}
      enableTouchCrosshair={false}
      layers={[
        'grid',
        'markers',
        'areas',
        'lines',
        CustomPointLabelLayer(properties),
        'points',
        'slices',
        'mesh',
        'axes',
        'legends',
      ]}
      legends={[
        {
          anchor: 'top-right',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: -50,
          itemsSpacing: 50,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'right-to-left',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};
