import { useEffect, useState } from 'react';
import './dashboard-cards.css';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css'; // Import styles
import 'react-resizable/css/styles.css';
import { DashboardCard } from '../../../../hooks/client/dashboard/dashboard-card';
import { TableCard } from '../../../../hooks/client/dashboard/dashboard-card';
import { useDashboardStructure } from '../../../../hooks/client/dashboard/dashboard-structure.hook';
import DrillDownModal from '../../../../hooks/client/dashboard/drill-down/drill-down.component';
import { Ellipsis } from 'lucide-react';

export const DashboardCardHolder = ({
  name,
  isLoading,
  cards,
  dateRange,
  dashboardTab,
}: {
  name: string;
  isLoading: boolean;
  cards: DashboardCard<unknown, unknown>[];
  dateRange: any | null | undefined;
  dashboardTab: string;
}) => {
  const [drillDown, setDrillDown] = useState<{
    date: {
      startDate: Date;
      endDate: Date;
    };
    name: string;
    identifier: string | null;
  }>({
    date: dateRange,
    name: '',
    identifier: null,
  });

  const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);

  const openDrilldown = (identifier: string, name: string) => {
    setDrillDown({
      ...drillDown,
      identifier: identifier,
      name: name,
    });
    setDropdownOpen(null);
  };

  useEffect(() => {
    setDrillDown((prev) => ({
      ...prev,
      date: dateRange,
    }));
  }, [dateRange]);

  const closeDrilldown = () => {
    setDrillDown({
      ...drillDown,
      identifier: null,
      name: '',
    });
  };

  const { saveDashboardStructure } = useDashboardStructure(dashboardTab);
  const [hasMounted, setHasMounted] = useState(false);

  const handleLayoutChange = (layout: any) => {
    if (!hasMounted) {
      return;
    }
    const layoutWithNames = layout.map((layoutItem: any) => {
      const card = cards.find((item) => item.i === layoutItem.i);
      const { x, y, w, h } = layoutItem;
      return {
        ...card,
        x,
        y,
        w,
        h,
      };
    });
    saveDashboardStructure(layoutWithNames, dashboardTab);
  };

  const toggleDropdown = (identifier: string) => {
    setDropdownOpen(dropdownOpen === identifier ? null : identifier);
  };

  useEffect(() => {
    if (cards.length === 0) {
      return;
    }
    setHasMounted(true);
  }, [cards]);

  if (isLoading) {
    return (
      <div className={'dashboard-card-holder'}>
        <p className={'name'}>{name}</p>
        <div className={'dashboard-loaded'}>
          <div className={'loading-item lg'}></div>
          <div className={'small-loaders'}>
            <div className={'loading-item sm'}></div>
            <div className={'loading-item sm'}></div>
            <div className={'loading-item sm'}></div>
            <div className={'loading-item sm'}></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={'dashboard-card-holder'}>
      <p className={'name'}>{name}</p>
      {drillDown.identifier && (
        <div className="modal-overlay">
          <DrillDownModal
            title={drillDown.name}
            dateRange={drillDown.date}
            metricType={drillDown.identifier}
            onClose={closeDrilldown}
          />
        </div>
      )}
      <GridLayout
        margin={[8, 8]}
        containerPadding={[0, 8]}
        className="layout"
        cols={6}
        rowHeight={49}
        width={1200}
        padding={[0, 0]}
        onLayoutChange={handleLayoutChange}
      >
        {cards.map((item) => {
          let background = 'white';

          if (item instanceof TableCard) {
            background = '#e5e5e5';
          }

          return (
            <div
              style={{ background: background, position: 'relative' }}
              key={item.i}
              data-grid={item}
              className="grid-card"
            >
              <Ellipsis
                style={{
                  position: 'absolute',
                  height: '20px',
                  width: '15px',
                  right: '10px',
                  cursor: 'pointer',
                }}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                onClick={() => toggleDropdown(item.i)}
              />
              {dropdownOpen === item.i && (
                <div className="dropdown-menu">
                  <button
                    onClick={() => openDrilldown(item.i, item.name || '')}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    Drill-down
                  </button>
                </div>
              )}
              {item.component}
            </div>
          );
        })}
      </GridLayout>
    </div>
  );
};
