import { reverseSign } from '../../../../utils/dashboard/formatters';
import { ChartDataItem } from '../../../../hooks/client/dashboard/interfaces/dashboard-data-item.interface';
import { HeaderItem, HeaderItemProps } from './header-item';

export const PlDiagramHeader = ({
  headerMetrics,
}: {
  headerMetrics: {
    revenue: ChartDataItem;
    grossProfit: ChartDataItem;
    opex: ChartDataItem;
    ebitda: ChartDataItem;
    netProfitSum: ChartDataItem;
    netProfitPercent: ChartDataItem;
  };
}) => {
  if (!headerMetrics) {
    return null;
  }

  const items: HeaderItemProps[] = [
    {
      name: '💰 REVENUE, $',
      value: headerMetrics.revenue,
      valueType: 'sum',
    },
    {
      name: '🛟 GROSS PROFIT, %',
      value: headerMetrics.grossProfit,
      valueType: 'number',
    },
    {
      name: '💵 OPEX, $',
      value: {
        month: headerMetrics.opex.month,
        year: headerMetrics.opex.year,
        monthData: {
          value: reverseSign(headerMetrics.opex.monthData.value),
        },
        prevMonthData: {
          value: reverseSign(headerMetrics.opex.prevMonthData.value),
        },
      },
      valueType: 'sum',
    },
    {
      name: '🔥 EBITDA, $',
      value: headerMetrics.ebitda,
      valueType: 'sum',
    },

    {
      name: '💵 NET PROFIT, $',
      value: headerMetrics.netProfitSum,
      valueType: 'sum',
    },
    {
      name: '💰 NET PROFIT, %',
      value: headerMetrics.netProfitPercent,
      valueType: 'number',
    },
  ];

  return (
    <div className={'header-holder'}>
      {items.map((item, index) => {
        return (
          <HeaderItem
            key={index}
            name={item.name}
            value={item.value}
            valueType={item.valueType}
          />
        );
      })}
    </div>
  );
};
