import { Histogram } from '../../../pages/dashboard/components/cards-content/components/histogram/histogram.component';
import { Summary } from '../../../pages/dashboard/components/cards-content/summary.component';
import { BalanceSummary } from '../../../pages/dashboard/components/cards-content/balance-summary.component';
import { ComparisonSummary } from '../../../pages/dashboard/components/cards-content/comparison-summary.component';
import { StackBarChart } from '../../../pages/dashboard/components/cards-content/stack-chart';
import { RaceBarChart } from '../../../pages/dashboard/components/cards-content/race-chart';
import {
  CardTable,
  CategoriesCardTable,
  TopCategoriesBalanceCardTable,
} from '../../../pages/dashboard/components/cards-content/components/table/table.component';
import { ResponsivePie } from '@nivo/pie';
import {
  CategoriesBalanceTableDataset,
  DynamicTableDataset,
  HistogramDataset,
  OpexCogsTableDataset,
  OutflowCategoriesTableDataset,
  PieChartDataset,
  RaceChartDataset,
} from './interfaces/dashboard-card.type';
import { reformatToThousands } from '../../../utils/dashboard/formatters';
import { LineChart } from '../../../pages/dashboard/components/cards-content/line-chart.component';
import { DynamicCardTable } from '../../../pages/dashboard/components/cards-content/components/table/dynamic-table';
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CombinedChartComponent } from '../../../pages/dashboard/components/cards-content/components/combined-chart/combined-chart';
import AreaChart from '../../../pages/dashboard/components/cards-content/area-chart.component';

const NoDataAvailableComonent = ({ name }: { name: string }) => {
  return (
    <div className="no-data-component">
      <p>{name}</p>
      <p>No data provided for the reporting month</p>
    </div>
  );
};

const withWrap = (Component: any) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        padding: '16px',
      }}
    >
      {Component}
    </div>
  );
};

export abstract class DashboardCard<T, K> {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minH: number;

  name?: string;
  type?: string;
  dashboardType?: string;

  component: JSX.Element | null = (<div>Card without implementation </div>);

  constructor(
    identifier: string,
    layout: {
      width: number;
      xPosition: number;
      yPosition: number;
      height: number;
      minH?: number;
    },
    data?: T,
    options?: K
  ) {
    this.i = identifier;
    this.x = layout.xPosition || 0;
    this.y = layout.yPosition || 0;
    this.w = layout.width || 1;
    this.h = layout.height || 1;
    this.minH = layout.minH || 1;
  }

  public setProperties(properties: any) {
    this.name = properties.name;
    this.dashboardType = properties.dashboardType;
    this.type = properties.type;
  }
}

export interface HistogramProperties {
  name?: string;
  dataset?: HistogramDataset;
}

export class HistogramCard extends DashboardCard<
  HistogramProperties,
  {
    colors?: string;
    percents?: boolean;
  }
> {
  constructor(
    identifier: string,
    layout?: any,
    data?: HistogramProperties,
    options?: { colors: string; enableLine: boolean }
  ) {
    super(identifier, { ...layout, minH: 2 }, data, options);
    this.component = (
      <div
        style={{
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
          padding: '16px',
        }}
      >
        <p>{data?.name}</p>
        <Histogram
          name={data?.name}
          dataset={data?.dataset}
          options={options}
        />
      </div>
    );
  }
}
export class SummaryCard extends DashboardCard<
  {
    name: string;
    value: number;
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: {
      name: string;
      dataset: {
        value: number;
        prevMonthData: number;
        year: number;
        month: number;
        signType: 'percent' | 'value';
      };
    }
  ) {
    super(identifier, { minH: 2, ...layout });

    if (!data.dataset || !Object.keys(data.dataset).length) {
      this.component = <NoDataAvailableComonent name={data.name} />;
      return;
    }

    this.component = withWrap(
      <Summary
        name={data.name}
        value={data.dataset.value}
        prevValue={data.dataset.prevMonthData}
        year={data.dataset.year}
        month={data.dataset.month}
        signType={data.dataset.signType}
      />
    );
  }
}

export class BalanceSummaryCard extends DashboardCard<
  {
    name: string;
    value: number | string;
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: {
      name: string;
      dataset: {
        value: number | string;
      };
    }
  ) {
    super(identifier, { minH: 2, ...layout });

    if (!data.dataset || !Object.keys(data.dataset).length) {
      this.component = <NoDataAvailableComonent name={data.name} />;
      return;
    }

    this.component = withWrap(
      <BalanceSummary name={data.name} value={data.dataset.value} />
    );
  }
}

export class ComparisonSummaryCard extends DashboardCard<
  {
    name: string;
    value: number;
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: {
      dataset: {
        firstValue: number;
        secondValue: number;
        year: number;
        month: number;
      };
    },
    options: { firstName: string; secondName: string }
  ) {
    super(identifier, { minH: 2, ...layout });

    if (!data.dataset || !Object.keys(data.dataset).length) {
      this.component = <NoDataAvailableComonent name={options.firstName} />;
      return;
    }

    this.component = withWrap(
      <ComparisonSummary
        firstName={options.firstName}
        secondName={options.secondName}
        firstValue={data.dataset.firstValue}
        secondValue={data.dataset.secondValue}
        year={data.dataset.year}
        month={data.dataset.month}
      />
    );
  }
}

export class RaceChartCard extends DashboardCard<
  {
    dataset: RaceChartDataset;
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: { name: string; dataset: RaceChartDataset }
  ) {
    super(identifier, layout);
    if (!data.dataset) {
      this.component = <NoDataAvailableComonent name={data.name} />;
      return;
    }

    this.component = withWrap(
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p>{data.name}</p>
          <span
            style={{
              color: '#1D1B2080',
              fontSize: '10px',
            }}
          >
            for {data.dataset.date}
          </span>
        </div>
        <RaceBarChart dataset={data.dataset} />
      </>
    );
  }
}

export class StackedBarCard extends DashboardCard<
  {
    dataset: any;
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: { name: string; dataset: HistogramDataset },
    options?: { colors?: string[]; percents?: boolean }
  ) {
    super(identifier, layout);

    if (!data.dataset) {
      this.component = <NoDataAvailableComonent name={data.name} />;
      return;
    }

    this.component = withWrap(
      <>
        <p>{data.name}</p>
        <StackBarChart dataset={data.dataset} options={options} />
      </>
    );
  }
}

export class TableCard extends DashboardCard<
  {
    dataset: OpexCogsTableDataset[];
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: { dataset: OpexCogsTableDataset; name: string }
  ) {
    super(identifier, layout);

    if (!data.dataset) {
      this.component = <NoDataAvailableComonent name={data.name} />;
      return;
    }

    this.component = <CardTable name={data.name} dataset={data.dataset} />;
  }
}

export class CategoriesTableCard extends DashboardCard<
  {
    dataset: OutflowCategoriesTableDataset[];
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: { dataset: OutflowCategoriesTableDataset; name: string }
  ) {
    super(identifier, layout);

    if (!data.dataset) {
      this.component = <NoDataAvailableComonent name={data.name} />;
      return;
    }

    this.component = (
      <CategoriesCardTable name={data.name} dataset={data.dataset} />
    );
  }
}

export class TopCategoriesBalanceCard extends DashboardCard<
  {
    dataset: CategoriesBalanceTableDataset[];
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: { dataset: CategoriesBalanceTableDataset; name: string }
  ) {
    super(identifier, layout);

    if (!data.dataset) {
      this.component = <NoDataAvailableComonent name={data.name} />;
      return;
    }

    this.component = (
      <TopCategoriesBalanceCardTable name={data.name} dataset={data.dataset} />
    );
  }
}

const PieChartValue = ({
  value,
  name,
}: {
  value: number | null;
  name: string;
}) => {
  const color = name === 'BUDGET' ? '#00000080' : 'black';

  return (
    <>
      <p style={{ color }} className={'pie-chart-card-name'}>
        {name}
      </p>
      <p style={{ color }} className={'pie-chart-card-value'}>
        {reformatToThousands(value || 0)}
      </p>
    </>
  );
};

export const PieChartContent = ({
  dataset,
  name,
}: {
  dataset: PieChartDataset;
  name: string;
}) => {
  const { name1, name2, value2, value1 } = dataset;

  const mapFromValues = [
    { id: name1, label: name1, value: value1, color: '#29b250' },
    {
      id: name2,
      label: name2,
      value: value2 === undefined ? 'No Data Available' : value2,
      color: '#dddddd',
    },
  ];

  return withWrap(
    <div className="pie-chart-card">
      <div className={'data'}>
        <p>{name}</p>
        <div>
          <div>
            <PieChartValue value={value1} name={name1} />
          </div>
          <div>
            <PieChartValue value={value2} name={name2} />
          </div>
        </div>
      </div>
      <div className={'pie-chart'}>
        <ResponsivePie
          sortByValue={true}
          data={mapFromValues}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          innerRadius={0.7} // Creates the doughnut shape
          padAngle={0.7}
          cornerRadius={0}
          colors={(d) => d.data.color}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          animate={false}
          enableArcLabels={false}
          motionConfig={'molasses'}
          transitionMode={'innerRadius'}
          enableArcLinkLabels={false}
        />
      </div>
    </div>
  );
};

export class PieChartCard extends DashboardCard<
  {
    dataset: any;
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: { name: string; dataset: any }
  ) {
    super(identifier, layout);

    if (!data.dataset || !data.dataset.value1 || !data.dataset.value2) {
      this.component = null;
      return;
    }

    this.component = (
      <PieChartContent name={data.name} dataset={data.dataset} />
    );
  }
}

export class LineChartCard extends DashboardCard<
  {
    dataset: any;
    curve?:
      | 'linear'
      | 'basis'
      | 'cardinal'
      | 'catmullRom'
      | 'monotoneX'
      | 'monotoneY'
      | 'natural'
      | 'step'
      | 'stepAfter'
      | 'stepBefore';
    enablePoints?: boolean;
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: {
      name: string;
      dataset: any;
      curve?:
        | 'linear'
        | 'basis'
        | 'cardinal'
        | 'catmullRom'
        | 'monotoneX'
        | 'monotoneY'
        | 'natural'
        | 'step'
        | 'stepAfter'
        | 'stepBefore';
      enablePoints?: boolean;
    },
    options?: any
  ) {
    super(identifier, layout);
    if (!data.dataset || !data.dataset.dataset.length) {
      console.log('no data');
      this.component = <NoDataAvailableComonent name={data.name} />;
      return;
    }
    this.component = withWrap(
      <>
        {data.name}
        <LineChart options={options} data={data.dataset} />
      </>
    );
  }
}

export class AreaChartCard extends DashboardCard<
  {
    dataset: any;
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: { name: string; dataset: any },
    options?: any
  ) {
    super(identifier, layout);
    if (data.dataset?.data?.length === 0) {
      console.log('no data');
      this.component = <NoDataAvailableComonent name={data.name} />;
      return;
    }
    this.component = withWrap(
      <>
        {data.name}
        <AreaChart options={options} data={data.dataset} />
      </>
    );
  }
}

export class DynamicTableCard extends DashboardCard<
  {
    dataset: DynamicTableDataset;
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: { name: string; dataset: DynamicTableDataset }
  ) {
    super(identifier, layout);
    if (!data.dataset) {
      this.component = null;
      return;
    }
    const { columns, data: tableData } = data.dataset;
    this.component = (
      <DynamicCardTable
        tableName={data.name}
        columns={columns}
        data={tableData}
      />
    );
  }
}

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    // Determine if the data represents a total, increase, or decrease
    const isTotal = data.name.includes('Total');
    const valueType = data.uv > 0 ? 'Increase' : 'Decrease';

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          padding: '10px',
          borderRadius: '5px',
        }}
      >
        {/* Displaying the name (date/label) */}
        <p>
          <strong>{data.name}</strong>
        </p>

        {/* Displaying the increase, decrease, or total value */}
        <p>
          {isTotal ? 'Total' : valueType}: {data.uv}
        </p>

        {/* Optionally displaying the previous value (pv) */}
        {data.pv !== 0 && <p>Previous Value (pv): {data.pv}</p>}
      </div>
    );
  }

  return null;
};
export class WaterfallCard extends DashboardCard<any, any> {
  constructor(identifier: string, layout: any, data: any, options: any) {
    const testWaterfallData = [
      // Year 1 (2021)
      { name: 'JAN 2021', uv: 50, pv: 0 }, // Increase
      { name: 'FEB 2021', uv: 30, pv: 50 }, // Increase
      { name: 'MAR 2021', uv: -20, pv: 80 }, // Decrease
      { name: 'APR 2021', uv: 40, pv: 60 }, // Increase
      { name: 'MAY 2021', uv: -10, pv: 100 }, // Decrease
      { name: 'JUNE 2021', uv: 20, pv: 90 }, // Increase

      // Half-Year Total for 2021
      { name: 'Half-Year Total 2021', uv: 110, pv: 0 }, // Total till June

      { name: 'JULY 2021', uv: 30, pv: 0 }, // Increase
      { name: 'AUG 2021', uv: -15, pv: 30 }, // Decrease
      { name: 'SEPT 2021', uv: 25, pv: 15 }, // Increase
      { name: 'OCT 2021', uv: -20, pv: 40 }, // Decrease
      { name: 'NOV 2021', uv: 30, pv: 20 }, // Increase
      { name: 'DEC 2021', uv: 50, pv: 50 }, // Increase

      // Full-Year Total for 2021
      { name: 'Total 2021', uv: 140, pv: 0 }, // Total till December

      // Year 2 (2022)
      { name: 'JAN 2022', uv: 60, pv: 0 }, // Increase
      { name: 'FEB 2022', uv: -25, pv: 60 }, // Decrease
      { name: 'MAR 2022', uv: 40, pv: 35 }, // Increase
      { name: 'APR 2022', uv: -15, pv: 75 }, // Decrease
      { name: 'MAY 2022', uv: 20, pv: 60 }, // Increase
      { name: 'JUNE 2022', uv: 30, pv: 80 }, // Increase

      // Half-Year Total for 2022
      { name: 'Half-Year Total 2022', uv: 110, pv: 0 }, // Total till June

      { name: 'JULY 2022', uv: 20, pv: 0 }, // Increase
      { name: 'AUG 2022', uv: -10, pv: 20 }, // Decrease
      { name: 'SEPT 2022', uv: 35, pv: 10 }, // Increase
      { name: 'OCT 2022', uv: -20, pv: 45 }, // Decrease
      { name: 'NOV 2022', uv: 40, pv: 25 }, // Increase
      { name: 'DEC 2022', uv: 50, pv: 65 }, // Increase

      // Full-Year Total for 2022
      { name: 'Total 2022', uv: 165, pv: 0 }, // Total till December
    ];
    const renderLegend = () => {
      return (
        <ul
          style={{
            display: 'flex',
            justifyContent: 'center',
            listStyle: 'none',
          }}
        >
          <li style={{ marginRight: 10, color: '#17c0bf' }}>● Increase</li>
          <li style={{ marginRight: 10, color: '#f5a623' }}>● Decrease</li>
          <li style={{ marginRight: 10, color: '#4a90e2' }}>● Total</li>
        </ul>
      );
    };
    super(identifier, layout);
    this.component = (
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: '',
          padding: '16px',
          boxSizing: 'border-box',
        }}
      >
        <p>{data.name}</p>
        <ResponsiveContainer>
          <BarChart
            layout={'vertical'}
            margin={{ bottom: 20 }}
            data={testWaterfallData}
          >
            <XAxis type="number" hide={true} />
            <YAxis type="category" dataKey="name" hide={true} />
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign={'top'} content={renderLegend()} />
            <Bar dataKey="pv" stackId="a" fill="#000000" fillOpacity={0} />
            <Bar dataKey="uv" stackId="a" fill="#82ca9d">
              {testWaterfallData.map((item, index) => {
                if (item.name.includes('Total'))
                  return <Cell key={index} fill="#4a90e2" />; // Blue for totals
                if (item.uv < 0) return <Cell key={index} fill="#f5a623" />; // Orange for decreases
                return <Cell key={index} fill="#17c0bf" />; // Teal for increases
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export class CombinedChartCard extends DashboardCard<any, any> {
  constructor(identifier: string, layout: any, data: any, options: any) {
    super(identifier, layout);
    this.component = withWrap(
      <>
        <p>{data.name}</p>
        <CombinedChartComponent data={data} options={options} />
      </>
    );
  }
}
