import './dashboard.style.css';
import { Updates } from './components/updates/updates.components';
import { DashboardCardHolder } from './components/dashboard-cards/dashboard-card-holder';
import { useSnapshotDashboard } from '../../hooks/client/dashboard/hooks/snapshot-dashboard.hook';
import { useContext, useState } from 'react';
import { useClientCharts } from '../charts/client-charts.hook';
import { RoleContext } from '../../context/role.context';
import { useManagerCharts } from '../../managers-pages/charts/manager-charts.hook';
import { ModalComponent } from '../../shared-components/modal/modal-component';
import lookerLogo from '../../../../public/images/looker-logo.png';
import PeriodSelector, {
  getDateRange,
} from './components/period-selector/period-selector.component';
import { SnapshotDiagramHeader } from './components/headers/snapshot-header.component';
import { CashflowDiagramHeader } from './components/headers/cashflow-header.component';
import { useCashflowDashboard } from '../../hooks/client/dashboard/hooks/cashflow-dashboard.hook';
import { usePlDashboard } from '../../hooks/client/dashboard/hooks/pl-dashboard.hook';
import { PlDiagramHeader } from './components/headers/pl-header.component';

const Snapshot = ({
  dateRange,
}: {
  dateRange: { startDate: Date; endDate: Date } | null;
}) => {
  const { cards, loading } = useSnapshotDashboard(dateRange);

  const { headerMetrics, cashflowCards, plCards, ungrouppedCards } = cards;

  const env = import.meta.env.VITE_APP_ENVIRONMENT;

  return (
    <div className={'charts-holder'}>
      <SnapshotDiagramHeader headerMetrics={headerMetrics} />
      <Updates />
      <DashboardCardHolder
        dateRange={dateRange}
        name={'CASHFLOW MANAGEMENT'}
        cards={cashflowCards}
        isLoading={loading}
        dashboardTab={'SNAPSHOT'}
      />
      <DashboardCardHolder
        name={'PROFIT & LOSS'}
        cards={plCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'SNAPSHOT'}
      />
      {env === 'development' && (
        <DashboardCardHolder
          name={'Test view'}
          cards={ungrouppedCards}
          isLoading={false}
          dateRange={dateRange}
          dashboardTab={'SNAPSHOT'}
        ></DashboardCardHolder>
      )}
    </div>
  );
};

const CashFlow = ({
  dateRange,
}: {
  dateRange: { startDate: Date; endDate: Date } | null;
}) => {
  const { cards, loading } = useCashflowDashboard(dateRange);

  const {
    headerMetrics,
    generalCards,
    operatingInflowCards,
    operatingOutflowCards,
    financialInvestingCards,
  } = cards;

  return (
    <div className={'charts-holder'}>
      <CashflowDiagramHeader headerMetrics={headerMetrics} />
      <Updates />
      <DashboardCardHolder
        name={'GENERAL'}
        cards={generalCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'CASHFLOW'}
      />
      <DashboardCardHolder
        name={'OPERATING INFLOW'}
        cards={operatingInflowCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'CASHFLOW'}
      />
      <DashboardCardHolder
        name={'OPERATING OUTFLOW'}
        cards={operatingOutflowCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'CASHFLOW'}
      />
      <DashboardCardHolder
        name={'FINANCIAL AND INVESTING ACTIVITY'}
        cards={financialInvestingCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'CASHFLOW'}
      />
    </div>
  );
};

const ProfitLoss = ({
  dateRange,
}: {
  dateRange: { startDate: Date; endDate: Date } | null;
}) => {
  const { cards, loading } = usePlDashboard(dateRange);

  const {
    headerMetrics,
    pLStatementCards,
    revenueCards,
    grossProfitMarginAndCogsCards,
    operationalExpensesCards,
    ebitdaNetProfitCards,
  } = cards;

  return (
    <div className={'charts-holder'}>
      <PlDiagramHeader headerMetrics={headerMetrics} />
      <Updates />
      <DashboardCardHolder
        name={'PROFIT AND LOSS STATEMENT (P&L) '}
        cards={pLStatementCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'PL'}
      />
      <DashboardCardHolder
        name={'REVENUE'}
        cards={revenueCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'PL'}
      />
      <DashboardCardHolder
        name={'GROSS PROFIT MARGIN  &  COST OF GOODS SOLD (COGS)'}
        cards={grossProfitMarginAndCogsCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'PL'}
      />
      <DashboardCardHolder
        name={'OPERATIONAL EXPENSES (OPEX)'}
        cards={operationalExpensesCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'PL'}
      />
      <DashboardCardHolder
        name={'EBITDA & NET PROFIT'}
        cards={ebitdaNetProfitCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'PL'}
      />
    </div>
  );
};

const ChartIframeContainer = ({ source }: { source: string }) => {
  return (
    <div
      style={{
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className={'dashboard-advanced-chart'}
    >
      Loading...
      <iframe
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          borderRadius: '10px',
          boxSizing: 'border-box',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        className={'chart-iframe'}
        title="looker-chart"
        src={source}
      />
    </div>
  );
};

const AdvancedClientWrapper = () => {
  const { charts } = useClientCharts();

  if (!charts) {
    return (
      <div className={'dashboard-advanced-placeholder'}>
        <p>🤖</p>
        <p>There would be custom metrics for you</p>
      </div>
    );
  }

  return <Advanced charts={charts} />;
};

const AdvancedManagerWrapper = () => {
  const { charts, createChart, deleteChart } =
    useManagerCharts('ADVANCED_METRICS');
  const [value, handleChangeValue] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const chartLinkValueChange = (e: any) => {
    handleChangeValue(e.target.value);
  };

  const addNewChart = () => {
    createChart({
      source: value,
    });
    handleChangeValue('');
  };

  const deleteLooker = async () => {
    // const chartId = charts[0].id;
    // setModalOpen(false);
    //
    // await deleteChart(chartId);
  };

  if (!charts) {
    return (
      <div className={'dashboard-advanced'}>
        <div className={'dashboard-advanced-placeholder'}>
          <div className={'looker-logo'}>
            <img
              style={{
                width: '60px',
                height: '60px',
              }}
              src={lookerLogo}
              alt={'looker'}
            />
          </div>
          <div>
            <input
              className="dashboard-advanced-input"
              value={value}
              onChange={chartLinkValueChange}
              placeholder={'Link'}
            />
            <button onClick={addNewChart} className="dashboard-advanced-button">
              Add
            </button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      {modalOpen && (
        <ModalComponent
          name={'Looker disconnecting'}
          isOpen={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
        >
          <p>Are you sure you want to disconnect Looker?</p>
          <button className={'disconnect-looker-button'} onClick={deleteLooker}>
            Disconnect Looker
          </button>
        </ModalComponent>
      )}
      <button
        className={'disconnect-looker-button'}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Disconnect Looker
      </button>
      <Advanced charts={charts}></Advanced>
    </>
  );
};

const AdvancedChartsHoc = () => {
  const { role } = useContext(RoleContext);

  if (role === 'CLIENT') {
    return <AdvancedClientWrapper />;
  }

  if (role === 'MANAGER') {
    return <AdvancedManagerWrapper />;
  }

  return null;
};

const Advanced = ({ charts }: { charts: any }) => {
  return (
    <div className={'dashboard-advanced'}>
      <ChartIframeContainer key={charts.id} source={charts.source} />
    </div>
  );
};

const DashboardMenuItem = ({ name, onClick, isActive }: any) => {
  return (
    <div onClick={onClick} className={`menu-item ${isActive ? 'active' : ''}`}>
      {name}
    </div>
  );
};

export const DashboardPage = () => {
  const [chosenItem, setChosenItem] = useState<
    'SNAPSHOT' | 'PROFIT_LOSS' | 'CASH_FLOW' | 'ADVANCED'
  >('SNAPSHOT');
  const [_, setSelectedPeriod] = useState('Last 12 months');
  const [dateRange, setDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  } | null>(getDateRange('Last 12 months'));

  const handleMenuClick = (
    page: 'SNAPSHOT' | 'PROFIT_LOSS' | 'CASH_FLOW' | 'ADVANCED'
  ) => {
    setChosenItem(page);
  };

  const handlePeriodSelect = (
    period: string,
    range: { startDate: Date; endDate: Date } | null
  ) => {
    setSelectedPeriod(period);
    setDateRange(range);
  };

  const formatDateRange = () => {
    if (dateRange?.startDate && dateRange?.endDate) {
      const start = dateRange.startDate.toLocaleDateString('en-GB');
      const end = dateRange.endDate.toLocaleDateString('en-GB');
      return `${start} - ${end}`;
    }
    return '';
  };

  return (
    <>
      <div className="dashboard-menu">
        <div className="menu">
          <DashboardMenuItem
            isActive={chosenItem === 'SNAPSHOT'}
            onClick={() => handleMenuClick('SNAPSHOT')}
            name={'SNAPSHOT'}
          />
          <DashboardMenuItem
            isActive={chosenItem === 'CASH_FLOW'}
            onClick={() => handleMenuClick('CASH_FLOW')}
            name={'CASH FLOW'}
          />
          {import.meta.env.VITE_APP_ENVIRONMENT !== 'production' && (
            <DashboardMenuItem
              isActive={chosenItem === 'PROFIT_LOSS'}
              onClick={() => handleMenuClick('PROFIT_LOSS')}
              name={'PROFIT & LOSS'}
            />
          )}
          <DashboardMenuItem
            isActive={chosenItem === 'ADVANCED'}
            onClick={() => handleMenuClick('ADVANCED')}
            name={'ADVANCED METRICS'}
          />
          <div className="menu-spacer"></div>
          {formatDateRange() && (
            <div className="selected-period">{formatDateRange()}</div>
          )}
          <PeriodSelector onSelectPeriod={handlePeriodSelect} />
        </div>
        <div className="menu-underline"></div>
      </div>
      {
        {
          SNAPSHOT: <Snapshot dateRange={dateRange} />,
          CASH_FLOW: <CashFlow dateRange={dateRange} />,
          PROFIT_LOSS: <ProfitLoss dateRange={dateRange} />,
          ADVANCED: <AdvancedChartsHoc />,
        }[chosenItem]
      }
    </>
  );
};
