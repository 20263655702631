// Combinet chart to support multiple data sets ( line and bar )
import {
  Bar,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  DiagramStringValueFormatBuilder,
  formatDate,
} from '../../../../../../utils/dashboard/formatters';

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul
      style={{
        listStyleType: 'none',
        margin: 0,
        padding: '0', // Add padding for space around the legend
        position: 'absolute', // Absolute positioning
        top: '-22px', // Adjust top to give more space between chart and legend
        right: '0', // Align legend to right
        display: 'flex', // Use flex to layout items horizontally
        flexDirection: 'row', // Row layout
        alignItems: 'center', // Align vertically centered
        background: 'white', // Optional: white background for better readability
        borderRadius: '5px', // Optional: rounded corners
        fontSize: '11px',
        marginBottom: '20px', // Add bottom margin for additional spacing
      }}
    >
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{ marginLeft: index > 0 ? '10px' : '0' }}
        >
          {' '}
          {/* Margin between legend items */}
          <span>{entry.value}</span>
          <svg width="20" height="10" style={{ marginLeft: '5px' }}>
            <rect fill={entry.color} width="10" height="10" />
          </svg>
        </li>
      ))}
    </ul>
  );
};

export const CombinedChartComponent = ({
  data,
  options,
}: {
  data: {
    dataset: {
      data: any[];
      keys: string[];
    };
  };
  options: {
    colors?: string | undefined;
    lineColor?: string | undefined;
  };
}) => {
  const defaultColour = '#C4DB71';

  const customColor = options.colors || defaultColour;

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <ResponsiveContainer>
        <ComposedChart data={data.dataset.data}>
          <Tooltip
            labelFormatter={(label) => {
              return formatDate(label);
            }}
            formatter={(
              value: number,
              name: string,
              entry: any,
              index: number
            ) => {
              if (index === 0) {
                return new DiagramStringValueFormatBuilder(value)
                  .toThousands()
                  .includeK()
                  .getValue();
              }
              return value;
            }}
          />

          <YAxis yAxisId="left" domain={['auto', 'auto']} hide={true} />

          <YAxis
            yAxisId="right"
            orientation="right"
            domain={[-100, 100]}
            hide={true}
          />

          <XAxis
            dataKey="date"
            tickFormatter={(tick) => formatDate(tick)}
            axisLine={false} // Remove the axis line
            tickLine={false} // Remove the tick line
            tick={{
              fontSize: 10,
            }}
          />

          <Legend
            verticalAlign={'top'}
            align={'right'}
            content={renderLegend}
          />

          <Bar
            yAxisId="left"
            dataKey={data.dataset.keys[0]}
            barSize={20}
            fill={customColor}
            label={{
              position: 'top',
              formatter: (value: number) =>
                new DiagramStringValueFormatBuilder(value)
                  .toThousands()
                  .includeK()
                  .getValue(),
              style: { fontSize: '10px', fill: '#000' }, // Smaller font size and color customization
            }}
          />

          <Line
            yAxisId="right"
            dataKey={data.dataset.keys[1]}
            stroke={options?.lineColor || '#428456'}
            fill={options?.lineColor || '#428456'}
            dot={false}
            strokeWidth={2}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
