import { ResponsiveStream } from '@nivo/stream';
import { formatDate } from '../../../../utils/dashboard/formatters';

interface AreaChartProps {
  data: any;
  options?: any;
}

const AreaChart = (properties: AreaChartProps) => (
  <ResponsiveStream
    data={properties.data.data}
    keys={properties.data.keys}
    margin={{ top: 50, right: 50, bottom: 50, left: 30 }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 0,
      legend: '',
      legendOffset: 36,
      format: (index: number) => formatDate(properties.data.data[index].date),
    }}
    axisLeft={null}
    enableGridX={false}
    enableGridY={false}
    offsetType="diverging"
    fillOpacity={0.7}
    curve="linear"
    borderColor={{ theme: 'background' }}
    colors={properties.options?.colors || { scheme: 'default' }}
    defs={[
      {
        id: 'dots',
        type: 'patternDots',
        background: 'inherit',
        color: '#2c998f',
        size: 4,
        padding: 2,
        stagger: true,
      },
      {
        id: 'squares',
        type: 'patternSquares',
        background: 'inherit',
        color: '#e4c912',
        size: 6,
        padding: 2,
        stagger: true,
      },
    ]}
    legends={[
      {
        anchor: 'top-right',
        direction: 'row',
        justify: false,
        translateX: 30,
        translateY: -40,
        itemsSpacing: 60,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'right-to-left',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
  />
);

export default AreaChart;
