import { instanceWithToken } from '../api';
import { AxiosData } from '../api-services/interfaces';
import { FilterDto } from './types/filter.dto';
import {
  MappingMasterList,
  MappingSource,
  IntegrationMapping,
  Mapping,
  SourceType,
} from './types/mapping';

export class ManagerHttpMappingService {
  public static getAvailableIntegrations(
    companyId: number,
    sourceType: SourceType
  ): Promise<AxiosData<MappingSource[]>> {
    return instanceWithToken.get(
      `mappings/${companyId}/available-sources?sourceType=${sourceType}`
    );
  }

  public static getMappingList(
    companyId: number,
    sourceType: SourceType,
    integrationId: number,
    filterDto?: FilterDto
  ): Promise<AxiosData<{ data: Mapping[]; totalCount: number }>> {
    return instanceWithToken.post(
      `mappings/${companyId}/list/${integrationId}?sourceType=${sourceType}`,
      {
        ...filterDto,
      }
    );
  }

  public static createMapping(companyId: number, data: IntegrationMapping) {
    return instanceWithToken.post(`mappings/${companyId}`, { ...data });
  }

  public static remap(
    companyId: number,
    data: {
      integrationId: number;
      sourceType: SourceType;
      updatedMappings: { id: number; from: string; to: string }[];
    }
  ) {
    return instanceWithToken.post(`mappings/${companyId}/remap`, { ...data });
  }

  public static filterMasterList(
    companyId: number,
    sourceType: SourceType,
    filterDto?: FilterDto
  ): Promise<AxiosData<{ data: MappingMasterList[]; totalCount: number }>> {
    return instanceWithToken.post(
      `mappings/${companyId}/master-lists/filter?sourceType=${sourceType}`,
      {
        ...filterDto,
      }
    );
  }

  public static getMasterList(
    companyId: number,
    sourceType: SourceType
  ): Promise<AxiosData<MappingMasterList[]>> {
    return instanceWithToken.get(
      `mappings/${companyId}/master-lists?sourceType=${sourceType}`
    );
  }

  public static addMasterLists(
    companyId: number,
    data: { integrationId: string; sourceType: SourceType }
  ) {
    return instanceWithToken.post(`mappings/${companyId}/master-lists`, {
      ...data,
    });
  }

  public static addMasterList(
    companyId: number,
    data: { name: string; sourceType: SourceType; settings: object }
  ) {
    return instanceWithToken.post(`mappings/${companyId}/master-lists/single`, {
      ...data,
    });
  }

  public static updateMasterList(
    companyId: number,
    masterListId: number,
    data: { name: string; sourceType: SourceType; settings: object }
  ) {
    return instanceWithToken.put(
      `mappings/${companyId}/master-lists/${masterListId}`,
      { ...data }
    );
  }

  public static deleteMasterList(
    companyId: number,
    masterListId: number,
    sourceType: SourceType
  ) {
    return instanceWithToken.delete(
      `mappings/${companyId}/master-lists/${masterListId}?sourceType=${sourceType}`
    );
  }
}
